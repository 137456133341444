import { Link } from "react-router-dom";

import "./App.css";

import arrow from "./assets/icons/arrow.svg";
import Header from "./components/header/header";

function App() {
  return (
    <div>
      <Header />
      <section>
        <h2>Kies uit de volgende scenario's:</h2>
        <Link to="/scenario1h" className="scenario">
          <p>Scenario 1 Hoog</p>
          <img src={arrow} alt="Open" />
        </Link>
        <Link to="/scenario2h" className="scenario">
          <p>Scenario 2 Hoog</p>
          <img src={arrow} alt="Open" />
        </Link>
        <Link to="/scenario3h" className="scenario">
          <p>Scenario 3 Hoog</p>
          <img src={arrow} alt="Open" />
        </Link>
        <Link to="/scenario1l" className="scenario">
          <p>Scenario 1 Laag</p>
          <img src={arrow} alt="Open" />
        </Link>
        <Link to="/scenario2l" className="scenario">
          <p>Scenario 2 Laag</p>
          <img src={arrow} alt="Open" />
        </Link>
        <Link to="/scenario3l" className="scenario">
          <p>Scenario 3 Laag</p>
          <img src={arrow} alt="Open" />
        </Link>
      </section>
    </div>
  );
}

export default App;
