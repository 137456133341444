import { LineChart } from "@mui/x-charts/LineChart";

import "../dashboard.css";

import auto from "../../../assets/icons/auto.svg";
import wasmachine from "../../../assets/icons/wasmachine.svg";
import telefoon from "../../../assets/icons/telefoon.svg";
import extra from "../../../assets/icons/extra.svg";

const xLabels = ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00"];
const wData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];

function Dashboard() {
  return (
    <section>
      <p>Verbruik in uw wijk:</p>

      <LineChart
        height={300}
        leftAxis={null}
        bottomAxis={null}
        margin={{
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
        }}
        slotProps={{ legend: { hidden: true } }}
        series={[{ data: wData, label: "Wijkverbruik", color: "#e15759" }]}
        xAxis={[{ scaleType: "point", data: xLabels }]}
      />
      <p>Voorkom netuitval, zet de volgende apparaten aan:</p>
      <div className="item">
        <img src={auto} alt="Auto opladen" />
        <label>
          Laad uw auto <b>op</b>
        </label>
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={wasmachine} alt="Wasmachine uit" />
        <label>
          Schakel uw wasmachine <b>aan</b>
        </label>
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={telefoon} alt="Telefoon opladen" />
        <label>
          Laad uw telefoon <b>op</b>
        </label>
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={extra} className="extra" alt="Apparaat toevoegen" />
        <label>Voeg uw eigen apparaat toe</label>
        <div height="34px" />
      </div>
    </section>
  );
}

export default Dashboard;
