import { useRef, useState } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";

import "./header.css";

import logo from "../../assets/icons/enexiwatt.svg";
import logop from "../../assets/icons/pink/enexiwattp.svg";
import burger from "../../assets/icons/burger.svg";
import close from "../../assets/icons/close.svg";
import arrow from "../../assets/icons/arrow.svg";
import postcode from "../../assets/icons/pink/postcodep.svg";
import wijk from "../../assets/icons/pink/wijkp.svg";
import energienet from "../../assets/icons/pink/energienetp.svg";
import survey from "../../assets/icons/pink/survey.svg";

function Header() {
  const context = useRef(null);
  const nav = useRef(null);

  const [openMenu, setOpenMenu] = useState(true);

  const menu = () => {
    let ctx = gsap.context(() => {
      if (openMenu) {
        gsap.to("#menu", {
          x: "-230px",
          display: "block",
          opacity: 1,
        });
        setOpenMenu(!openMenu);
      } else {
        gsap.to("#menu", {
          x: "100%",
          display: "none",
          opacity: 0,
        });
        setOpenMenu(!openMenu);
      }
    }, context);

    return () => ctx.revert();
  };

  return (
    <header ref={context}>
      <Link to="/" className="logo">
        <img src={logo} alt="Enexiwatt" />
        <h1>ENEXIWATT</h1>
      </Link>
      <nav ref={nav}>
        <button onClick={() => menu()}>
          <img src={burger} className="menu__icon" alt="Open" />
        </button>
        <div id="menu">
          <div className="menu__header">
            <button onClick={() => menu()}>
              <img src={close} className="menu__icon" alt="Open" />
            </button>
            <div className="logo">
              <img src={logop} alt="Enexiwatt menu" />
              <h1>ENEXIWATT</h1>
            </div>
          </div>
          <a
            href="https://www.enexis.nl/storingen-en-onderhoud/problemen-bij-u-thuis/controleren-op-spanningsproblemen"
            target="_blank"
            rel="noreferrer"
            className="info first"
          >
            <img src={postcode} alt="Postcode checker" />
            <div>
              <label>
                <b>Postcodechecker</b>
              </label>
              <label className="klein">Inzicht in jouw postcodegebied</label>
            </div>
            <img src={arrow} alt="Open" />
          </a>
          <a
            href="https://www.enexis.nl/storingen-en-onderhoud/problemen-bij-u-thuis"
            target="_blank"
            rel="noreferrer"
            className="info"
          >
            <img src={wijk} alt="Wijkinzicht" />
            <div>
              <label>
                <b>Wijkinzicht</b>
              </label>
              <label className="klein">
                Inzicht in het verbruik in jouw wijk
              </label>
            </div>
            <img src={arrow} alt="Open" />
          </a>
          <a
            href="https://www.enexis.nl/energietransitie/buurtaanpak"
            target="_blank"
            rel="noreferrer"
            className="info"
          >
            <img src={energienet} alt="Energienet" />
            <div>
              <label>
                <b>Energienet</b>
              </label>
              <label className="klein">Inzicht in het energienet</label>
            </div>
            <img src={arrow} alt="Open" />
          </a>
          <a
            href="https://forms.office.com/e/FWUusPLyHj"
            target="_blank"
            rel="noreferrer"
            className="info last"
          >
            <img src={survey} alt="Enquête" />
            <div>
              <label>
                <b>Enquête</b>
              </label>
              <label className="klein">Geef ons feedback</label>
            </div>
            <img src={arrow} alt="Open" />
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
