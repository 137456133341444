import { useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";

import "../dashboard.css";

import autosvg from "../../../assets/icons/auto.svg";
import wasmachinesvg from "../../../assets/icons/wasmachine.svg";
import telefoonsvg from "../../../assets/icons/telefoon.svg";
import extra from "../../../assets/icons/extra.svg";

const xLabels = ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00"];
const wData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const aData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];

function Dashboard() {
  const [auto, setAuto] = useState(false);
  const [wasmachine, setWasmachine] = useState(false);
  const [telefoon, setTelefoon] = useState(false);

  const doAuto = () => {
    if (auto === false) {
      aData[6] -= 1000;
    } else if (auto === true) {
      aData[6] += 1000;
    }
  };

  const doWasmachine = () => {
    if (wasmachine === false) {
      aData[6] -= 1000;
    } else if (wasmachine === true) {
      aData[6] += 1000;
    }
  };

  const doTelefoon = () => {
    if (telefoon === false) {
      aData[6] -= 1000;
    } else if (telefoon === true) {
      aData[6] += 1000;
    }
  };

  return (
    <section>
      <p>Wijkverbruik vs eigen verbruik</p>

      <LineChart
        height={300}
        leftAxis={null}
        bottomAxis={null}
        margin={{
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
        }}
        slotProps={{ legend: { hidden: true } }}
        series={[
          { data: wData, label: "Wijkverbruik", color: "#e15759" },
          { data: aData, label: "Eigen gebruik", color: "#59a14f" },
        ]}
        xAxis={[{ scaleType: "point", data: xLabels }]}
      />
      <p>Voorkom netuitval, schakel de volgende apparaten uit:</p>
      <div className="item">
        <img src={autosvg} alt="Auto opladen" />
        <label>
          Laad uw auto <b>niet</b> op
        </label>
        <label className="switch">
          <input
            type="checkbox"
            onChange={() => {
              setAuto(!auto);
              doAuto();
            }}
          />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={wasmachinesvg} alt="Wasmachine uit" />
        <label>
          Schakel uw wasmachine <b>uit</b>
        </label>
        <label className="switch">
          <input
            type="checkbox"
            onChange={() => {
              setWasmachine(!wasmachine);
              doWasmachine();
            }}
          />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={telefoonsvg} alt="Telefoon opladen" />
        <label>
          Laad uw telefoon <b>niet</b> op
        </label>
        <label className="switch">
          <input
            type="checkbox"
            onChange={() => {
              setTelefoon(!telefoon);
              doTelefoon();
            }}
          />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={extra} className="extra" alt="Apparaat toevoegen" />
        <label>Voeg uw eigen apparaat toe</label>
        <div height="34px" />
      </div>
    </section>
  );
}

export default Dashboard;
