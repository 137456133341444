import "./morgen.css";

import arrow from "../../assets/icons/arrow.svg";

function Morgen({ zon }) {
  return (
    <>
      {zon ? (
        <section id="zon">
          <div className="optie">
            <p>Wat kan ik morgen doen?</p>
            <img src={arrow} alt="Open" />
          </div>
        </section>
      ) : (
        <section id="wolk">
          <div className="optie">
            <p>Wat kan ik morgen doen?</p>
            <img src={arrow} alt="Open" />
          </div>
        </section>
      )}
    </>
  );
}

export default Morgen;
