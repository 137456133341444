import "./welkom.css";

function Welkom() {
  return (
    <div id="welkom">
      <p>
        Goedemiddag, het verbruik in uw wijk is <b>te laag</b>
      </p>
    </div>
  );
}

export default Welkom;
