import "../dashboard.css";

import auto from "../../../assets/icons/auto.svg";
import wasmachine from "../../../assets/icons/wasmachine.svg";
import telefoon from "../../../assets/icons/telefoon.svg";
import extra from "../../../assets/icons/extra.svg";

function Dashboard() {
  return (
    <section>
      <p>Voorkom netuitval, schakel de volgende apparaten uit:</p>
      <div className="item">
        <img src={auto} alt="Auto opladen" />
        <label>
          Laad uw auto <b>niet</b> op
        </label>
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={wasmachine} alt="Wasmachine uit" />
        <label>
          Schakel uw wasmachine <b>uit</b>
        </label>
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={telefoon} alt="Telefoon opladen" />
        <label>
          Laad uw telefoon <b>niet</b> op
        </label>
        <label className="switch">
          <input type="checkbox" />
          <span className="slider round" />
          <span className="text"></span>
        </label>
      </div>
      <div className="item">
        <img src={extra} className="extra" alt="Apparaat toevoegen" />
        <label>Voeg uw eigen apparaat toe</label>
        <div />
      </div>
    </section>
  );
}

export default Dashboard;
