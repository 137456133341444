import Header from "../../components/header/header";
import Meer from "../../components/meer/meer";
import Morgen from "../../components/morgen/morgen";
import Dashboard from "../../components/dashboard/s2/dashboard_s2h";
import Welkom from "../../components/welkom/welkomh";
import Footer from "../../components/footer/footer";
import { useState } from "react";

function Scenario2() {
  const [zon] = useState(true);

  return (
    <div>
      <Header />
      <Welkom />
      <Dashboard />
      <Morgen zon={zon} />
      <Meer />
      <Footer />
    </div>
  );
}

export default Scenario2;
