import React from "react";

import arrow from "../../assets/icons/arrow.svg";
import postcode from "../../assets/icons/postcode.svg";
import wijk from "../../assets/icons/wijk.svg";
import energienet from "../../assets/icons/energienet.svg";

function InfoItem({ href, imgSrc, alt, label, smallLabel }) {
  return (
    <a href={href} target="_blank" rel="noreferrer" className="info">
      <img src={imgSrc} alt={alt} />
      <div>
        <label>{label}</label>
        <label className="klein">{smallLabel}</label>
      </div>
      <img src={arrow} alt="Open" />
    </a>
  );
}

function Meer() {
  const infoItems = [
    {
      href: "https://www.enexis.nl/storingen-en-onderhoud/problemen-bij-u-thuis/controleren-op-spanningsproblemen",
      imgSrc: postcode,
      alt: "Postcode checker",
      label: "Postcodechecker",
      smallLabel: "Inzicht in jouw postcodegebied",
    },
    {
      href: "https://www.enexis.nl/storingen-en-onderhoud/problemen-bij-u-thuis",
      imgSrc: wijk,
      alt: "Wijkinzicht",
      label: "Wijkinzicht",
      smallLabel: "Inzicht in het verbruik in jouw wijk",
    },
    {
      href: "https://www.enexis.nl/energietransitie/buurtaanpak",
      imgSrc: energienet,
      alt: "Energienet",
      label: "Energienet",
      smallLabel: "Inzicht in het energienet",
    },
  ];

  return (
    <section>
      <p>Kijk hier voor meer info</p>
      {infoItems.map((item, index) => (
        <InfoItem key={index} {...item} />
      ))}
    </section>
  );
}

export default Meer;
