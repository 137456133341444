import "./footer.css";

import logo from "../../assets/icons/enexiwatt.svg";

function Footer() {
  return (
    <footer>
      <div className="logo">
        <img src={logo} alt="Enexiwatt" />
        <h1>ENEXIWATT</h1>
      </div>
    </footer>
  );
}

export default Footer;
